import React from "react";
import domainImg from '/src/assets/images/transfer-domain/domain-search.png'
import rocketImg from '/src/assets/images/transfer-domain/rocket.png'
import searchRevertImg from '/src/assets/images/transfer-domain/search-revert.png'
import oneImg from '/src/assets/images/register-domains/1.png'
import twoImg from '/src/assets/images/register-domains/2.png'
import threeImg from '/src/assets/images/register-domains/3.png'

import '/src/assets/css/TransferDomain/transfer-steps.css'

const TransferSteps = () => {
    return (
        <section id='transfer-steps'>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className='h3-title main-color'>3 steps domain transfer</h3>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-one-content">
                        <img src={domainImg} alt="Search domain" className="img-fluid step-one-illustration"/>
                        <div className="content-box">
                            <img src={oneImg} className='step-img mb-3' alt="One" loading="lazy"/>
                            <p className='main-color'>Contact your current website domain provider to obtain the required EPP needed for any domain transfer.</p>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-two-content">
                        <img src={rocketImg} alt="Transfer" className="img-fluid step-two-illustration"/>

                        <div className="content-box">
                            <img src={twoImg} className='step-img mb-3' alt="Two" loading="lazy"/>
                            <p className='main-color'>Type your domain name in the section above and enter the EPP code provided by your current provider.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 step-three-content">
                        <img src={searchRevertImg} alt="Search domain" className="img-fluid step-three-illustration"/>

                        <div className="content-box">
                            <img src={threeImg} className='step-img mb-3' alt="Three" loading="lazy"/>
                            <p className='main-color'>Authorize the transfer via the received email  Don't forget to look also in the SPAM folder for the email.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TransferSteps