import React, {useState} from "react";
import '/src/assets/css/TransferDomain/transfer-domain-intro.css'
import introImg from '/src/assets/images/transfer-domain/intro.png'
import axios from "axios";
import Feedback from "../Feedback/Feedback";

const TransferDomainIntro = () => {

    const whmcsUrl = 'https://client.sitebunker.net/includes/api.php'
    const username = 'hMnIRhPjfy8s0o2TYHdgAHDAX5yQ4lVC'
    const password = 'vFIlXlkxLWW6ocAcY6L3l6p1QEsyznRg'
    const accessKey = 'SQAwpa3gpNty5vhw'
    const actionType = 'DomainWhois'



    const [activat, setActivat] = useState(false)
    const [available, setAvailable] = useState(null)
    const [inchide, setInchide] = useState(false)
    const [domeniu, setDomeniu] = useState("")
    const [loading, setLoading] = useState(false)


    const onGetData = () => {
        setLoading(true)
        let inputValue = document.querySelector("input").value
        axios.post(whmcsUrl, null, {
            params: {
                username: username,
                password: password,
                accesskey: accessKey,
                action: actionType,
                responsetype: 'json',
                domain:  inputValue.includes('www.') ? inputValue.slice(4) : inputValue
            }
        }).then(response => {
            if (response.data.result === 'success') {
                setDomeniu( inputValue.includes('www.') ? inputValue.slice(4) : inputValue)
                setActivat(true)
                setAvailable(response.data.status)
                setLoading(false)
            } else {
                setLoading(false)
                setActivat(false)
            }

        }).catch(error => {
            console.log(error)
        })

    }

    const onClose = (valoare) => {
        setActivat(valoare)
    }

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            onGetData()
        }
    }


    return (
        <section id='transfer-domain-intro'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-lg-7 col-12">

                        {/*Header Section*/}
                        <div className="header-section">
                            <h2 className='h2-title main-color mb-4'>Domain transfer</h2>
                            <p>Now it’s easy to successfully finish any domain transfer with SiteBunker.net</p>
                        </div>


                        {/*Input*/}
                        <div className="input-group">
                            <input type="text" className="form-control search-domain"
                                   placeholder="Enter domain name here..."
                                   onKeyPress={handleKeypress}
                                   pattern={'^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$'}/>
                            <div className="input-group-append ">
                                <button className="search-btn" type="button" onClick={(e) => onGetData(e)}>
                                    <span className='d-md-inline-block d-none'>Transfer</span>
                                    <i className='d-md-none d-inline-block flaticon-right font-weight-bolder'></i>
                                </button>
                            </div>
                        </div>


                        {/*Feedback Message*/}
                        <div className="d-flex mt-2">
                            {loading ? <div className="spinner-border text-info" role="status">
                                <span className="sr-only">Loading...</span>
                            </div> : null}
                            {activat && !loading ?
                                <Feedback domeniu={domeniu} inchide={onClose} stare={inchide} available={available}
                                          type={'Transfer'}/>
                                : null}
                        </div>
                    </div>

                    {/*Img*/}
                    <img src={introImg} alt="Intro" className='img-fluid transfer-domain-img'/>
                </div>
            </div>
        </section>
    )
}

export default TransferDomainIntro
