import React from "react";
import '/src/assets/css/TransferDomain/why-transfer-to-us.css'
import BenefitsWithIconList from "../Common/BenefitsWithIconList";

// Img
import transferImg from '/src/assets/images/transfer-domain/transfer.png'

// SVG
import SvgFile from "/src/assets/svgComponents/file-text.svg"
import SvgChat from "/src/assets/svgComponents/chat.svg"
import SvgShield from "/src/assets/svgComponents/shild.svg"
import SvgTime from "/src/assets/svgComponents/time.svg"
import SvgFolder from "/src/assets/svgComponents/folder.svg"
import SvgMoney from "/src/assets/svgComponents/money.svg"


const advantagesList = [
    {
        title: 'Time & Cost Efficiency',
        svgImg: <SvgTime/>,
        description: 'Save time and money by managing your domain and hosting, SSL certificates and more with SiteBunker.net offered benefits.'
    },
    {
        title: 'Predictable costs',
        svgImg: <SvgMoney/>,
        description: 'You can do any domain transfer at SiteBunker.net without any hidden costs.'
    },
    {
        title: 'Easy administration',
        svgImg: <SvgFolder/>,
        description: 'With the management system offered by SiteBunker.net you can easily manage at any time your domains as you wish.'
    },
    {
        title: '24/7 Support included',
        svgImg: <SvgChat/>,
        description: 'The SiteBunker.net support team is ready to assist you in any technical challenge, regardless of the time or day.'
    },
    {
        title: 'Secure domain transfer',
        svgImg: <SvgShield/>,
        description: 'The transfer of your website domain is done without any risk, in a completely secure process, monitored by our team.'
    },
    {
        title: 'Availability extension',
        svgImg: <SvgTime/>,
        description: 'Always remember that when you finish your domain transfer, the availability will be extended 1 extra year (except for the special TLDs).'
    }
]

const WhyTransferToUs = () => {
    return (
        <section id='why-transfer-to-us' className='grey-bg'>
            <div className="container">
                <div className="row position-relative">
                    <div className="col-12 text-center mb-5">
                        <h3 className='h3-title main-color mb-0'>Reasons why you should transfer your website domain at
                            SiteBunker.net</h3>
                        <p>Enjoy maximum efficiency and increased security at affordable prices.</p>
                    </div>

                    <div className="col-lg-7 col-12">
                        <p>Save precious time with SiteBunker.net by managing both your domain and your preferred
                            hosting subscription in one place. Always choose professional web hosting services, with
                            24/7 support included for your online business or for your web application. The 24/7 support
                            is available for all our services.</p>
                    </div>

                    <img src={transferImg} className="transfer-img img-fluid" alt="Transfer" loading="lazy"/>

                </div>
            </div>

            {/*Advantages*/}
            <BenefitsWithIconList dataList={advantagesList} isGreyBackground={true}/>
        </section>
    )
}

export default WhyTransferToUs
