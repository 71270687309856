import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import TransferDomainIntro from "../components/TransferDomain/TransferDomainIntro";
import SimpleFAQS from "../components/Common/SimpleFAQS";
import TransferSteps from "../components/TransferDomain/TransferSteps";
import WhyTransferToUs from "../components/TransferDomain/WhyTransferToUs";
import GetInTouch from "../components/ColocationServers/GetInTouch";
import SEO from "../components/App/SEO";


const faqs = [
    {
        title: 'How long does it take to finish a domain transfer?',
        text1: 'Depending on the current provider, a domain transfer process can take between 24 hours and 7 days.',
    },
    {
        title: 'What are the costs for domain transfer?',
        text1: 'Domain transfer costs vary depending on the extension type and the minimum extension period. You can contact the SiteBunker.net support team at any time for questions about costs.',
    }
]

const DomainTransfer = () => {
    return (
        <Layout>

            {/*SEO*/}
            <SEO title="Easy domain transfer at SiteBunker.net. Ask for details here!"
                 description="Now you can do as many domain transfer as you need in a fast, easy and secure way. Contact the website domain support team and request full details."
            />

            {/*NavBar*/}
            <Navbar/>

            {/*Intro*/}
            <TransferDomainIntro/>

            {/*Transfer Steps*/}
            <TransferSteps/>

            {/*Why Transfer To US*/}
            <WhyTransferToUs/>

            {/*Get in touch*/}
            <GetInTouch/>

            {/*Transfer FAQS*/}
            <SimpleFAQS title='We are ready to assist you in any question regarding website domain transfer'
                        subTitle='Read the below answers to the most frequently asked questions about domain transfer.'
                        questionsData={faqs}/>

            {/*Footer*/}
            <Footer/>
        </Layout>
    )
}

export default DomainTransfer